import React from 'react';
import {graphql} from 'gatsby';
import "../fragments/static/category";

import Category from "../components/Category";

export default function CategoryPage({data, pageContext}) {
    if (pageContext.categoryId === undefined) {
        return null;
    }

    const category = data.magento.categoryList[0];

    return <Category category={category} />;
}


export const query = graphql`
query categoryList($categoryId: String) {
    magento {
        categoryList: categoryList(filters: { ids: { eq: $categoryId } }) {
            ...CategoryFields
            products {
                items
                {
                    ...ProductFields
                    ...BundleProductFields
                }
            }
        }
    }
}
`
